<template>
	<div class="not-found position-absolute top-0 bottom-0 start-0 end-0">
		<div class="d-flex pt-5 px-20 bg-white position-absolute top-0 bottom-0 start-0 end-0"
			 style="background-position: center; background-repeat: no-repeat; background-size: cover;"
			:style="'background-image: url('+this.backgroundImage+')'">
			<div class="lead position-absolute start-0 end-0 top-50 bottom-0">
				<div class="position-absolute start-0 end-0 top-50 d-flex flex-column align-content-center">
					<h1 class="display-1 text-center text-danger fw-bold">Ошибка 404</h1>
					<h2 class="text-center text-dark">Страница не найдена!</h2>
					<router-link to="/" class="d-inline-flex align-self-center mx-auto link link-dashed mt-4">На главную</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: "not-found",
	data() {
		return {
			title: "Ошибка 404",
			backgroundImage: require('@/assets/svg/error-bg.svg')
		};
	},
	methods: {
	},
	components: {
	},
	mounted() {

	},
	watch: {
	}
};
</script>